 <template>
    <v-app class="dynamic" id="v-app-embed">
        <div class="d-flex flex-column flex-grow-1 mr-4" style="overflow: hidden">
            <h2 class="mb-4">Send2API</h2>
            <v-row v-if="isNotLegacy">
                <v-col v-if="devMode" cols="12">
                    <v-switch v-if="false" class="px-2" v-model="wizard">
                        <template v-slot:label>
                            <FaIcon class="mr-2" icon="laptop-code"/>
                            Wizard
                        </template>
                    </v-switch>
                    <b-input-group class="mb-2">
                        <b-input-group-prepend class="p-0 col-md-3 col-12">
                            <b-input-group-text>
                                <FaIcon icon="key" />
                                <span class="ml-1">Conversation ID</span>
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                            type="text"
                            id="conversationId"
                            name="conversationId"
                            placeholder="UUID"
                            :state="validateUuid()"
                            v-model="conversationId" />
                    </b-input-group>
                </v-col>
            </v-row>
            <v-row v-if="isNotLegacy">
                <v-col cols="12">
                    <v-stepper v-model="wizardStep">
                        <v-stepper-header>
                            <v-stepper-step :edit-icon="null" :editable="!wizard" :complete="wizardStep > 1" :step="1">
                                {{ $t('WIZARD.SEND2API.ENDPOINT.TITLE') }}
                                <small>{{ $t('WIZARD.SEND2API.ENDPOINT.DESCRIPTION') }}</small>
                            </v-stepper-step>
                            <v-divider v-if="wizard"/>
                            <v-stepper-step :edit-icon="null" :editable="!wizard" :complete="wizardStep > 2" :step="2">
                                {{ $t('WIZARD.SEND2API.QUESTIONS.TITLE') }}
                                <small>{{ $t('WIZARD.SEND2API.QUESTIONS.DESCRIPTION') }}</small>
                            </v-stepper-step>
                            <v-divider v-if="wizard"/>
                            <v-stepper-step :edit-icon="null" :editable="!wizard" :complete="wizardStep > 3" :step="3">
                                {{ $t('WIZARD.SEND2API.STRUCTURE.TITLE') }}
                                <small>{{ $t('WIZARD.SEND2API.STRUCTURE.DESCRIPTION') }}</small>
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                </v-col>
            </v-row>
            <v-row>
                <v-tabs v-if="devMode && isNotLegacy && wizardStep > 1" v-model="previewTab">
                    <v-tab key="v1" @click="payloadSource = 0" v-if="devMode">Legacy Payload</v-tab>
                    <v-tab key="v2" @click="payloadSource = 1">Payload</v-tab>
                    <v-tab key="diff" @click="payloadSource = 2" v-if="devMode">Diff from Legacy</v-tab>
                </v-tabs>
            </v-row>
            <div class="d-flex flex-row mt-2">
                <v-card v-show="wizardStep == 1 || payloadSource != 2" :class="{'config': true, 'flex-grow-1': true, 'full': !showOutputPreview}">
                    <v-stepper v-model="wizardStep">
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <EndpointConfigurator v-model="actionModel" :readonly="isScriptLive()" />
                            </v-stepper-content>

                            <v-stepper-content step="2" class="p-0">
                                <QuestionsConfigurator v-model="selectedQuestionsUuids"
                                                       :instructions="questionInstructions" />
                            </v-stepper-content>

                            <v-stepper-content step="3">
                                <StructureConfigurator
                                    v-model="selectedOptions"
                                    :options-definitions="optionsDefinitions" />
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card>
                <v-card v-if="showOutputPreview" class="output-preview flex-grow-1 p-0">
                    <vue-monaco-diff-editor
                        v-if="payloadSource == 2"
                        :style="{'min-height': '60vh'}"
                        :original="legacyOutput"
                        :modified="output"
                        language="json"
                        :options="monacoEditorOptions"
                    />
                    <vue-monaco-editor
                        v-else
                        :style="{'min-height': '60vh'}"
                        v-model="payload"
                        language="json"
                        :options="monacoEditorOptions"
                    />
                </v-card>
            </div>
            <v-row v-if="isNotLegacy" class="mt-2">
                <v-col cols="12">
                    <v-card class="p-4">
                        <v-item-group class="gapped">
                            <v-btn v-if="[2, 3].includes(wizardStep)" @click="wizardStep = wizardStep - 1">{{ $t('WIZARD.PREVIOUS') }}</v-btn>
                            <v-btn v-if="[1, 2].includes(wizardStep)" @click="wizardStep = wizardStep + 1" color="primary" :disabled="!wizardStepValid()">{{ $t('WIZARD.NEXT') }}</v-btn>
                            <v-btn v-if="[3].includes(wizardStep) && wizard" @click="finishWizard" color="primary" :disabled="!wizardStepValid()">{{ $t('WIZARD.SAVE') }}</v-btn>
                        </v-item-group>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="mt-2">
                <v-col cols="12">
                    <v-card class="p-4">
                        <v-item-group class="gapped">
                            <v-btn v-if="[1].includes(wizardStep) && wizard" @click="finishWizard" color="primary" :disabled="!wizardStepValid()">{{ $t('WIZARD.SAVE') }}</v-btn>
                        </v-item-group>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<script lang="ts">

/* eslint import/no-extraneous-dependencies: 0 */

import vue from 'vue';
import AbbiTextInput from '@/components/input/AbbiTextInput.vue';
import ActionModel from '@/models/action.model';
import ScriptModel from '@/models/script.model';
import ObjectConfigurator from '@/components/chat/configurators/action/ObjectConfigurator.vue';
import ListGroupItemInput from '@/components/form/ListGroupItemInput.vue';
import {VueMonacoDiffEditor, VueMonacoEditor} from '@guolao/vue-monaco-editor';
import ChatApi from '@/apis/chat.api';

import EndpointConfigurator from '@/components/chat/configurators/action/Send2API/EndpointConfigurator.vue';
import QuestionsConfigurator from '@/components/chat/configurators/action/Send2API/QuestionsConfigurator.vue';
import StructureConfigurator from '@/components/chat/configurators/action/Send2API/StructureConfigurator.vue';
import {STATUS_CLOSED, STATUS_LIVE} from '@/helpers/constants';
import {TranslateResult} from 'vue-i18n';
import InstructionModel from '@/models/instruction.model';
import OptionGroup from '@/components/chat/configurators/action/Send2API/OptionGroup';

export default vue.extend({
    name: 'Send2ApiConfigurator',
    components: {
        EndpointConfigurator,
        QuestionsConfigurator,
        StructureConfigurator,
        ListGroupItemInput,
        ObjectConfigurator,
        AbbiTextInput,
        VueMonacoEditor,
        VueMonacoDiffEditor,
    },
    props: {
        action: {
            type: [Object, ActionModel],
            required: true,
        },
        script: {
            type: [Object, ScriptModel],
            required: true,
        },
    },
    watch: {
        fields(value: string[]): void {
            if (this.actionModel.params === null) {
                this.actionModel.params = [];
            }

            if (!this.wizard) {
                this.actionModel.params.fields = value;
            }
        },
        version(value: number): void {
            if (this.actionModel.params === null) {
                this.actionModel.params = [];
            }

            if (!this.wizard) {
                this.actionModel.params.version = value;
            }
        },
        selectedQuestionsUuids: {
            handler(questionUuids: string[]): void {
                if (this.actionModel.params === null) {
                    this.actionModel.params = [];
                }

                this.actionModel.params.questions = questionUuids;
                this.updatePayloadPreview();
            },
            deep: true,
        },
        selectedOptions: {
            handler(options: string[]): void {
                if (this.actionModel.params === null) {
                    this.actionModel.params = [];
                }

                this.actionModel.params.options = options;
                this.updatePayloadPreview();
            },
            deep: true,
        },
        wizard(state: boolean): void {
            if (!state) {
                this.draft = state;
                this.action.params = this.draftAction.params;

                if (this.actionModel.params?.wizard !== undefined) {
                    delete this.actionModel.params.wizard;
                }
                if (this.actionModel.params?.draft !== undefined) {
                    delete this.actionModel.params.draft;
                }
            }
        },
    },
    data() {
        return {
            draftAction: new ActionModel() as ActionModel,
            wizard: false,
            draft: false,
            wizardStep: 1,
            selectedQuestionsUuids: null as string[] | null,
            monacoEditorOptions: {
                readOnly: true,
                minimap: {
                    enabled: false,
                },
                scrollBeyondLastLine: false,
                wordWrap: 'on',
            },
            optionsDefinitions: [
                {
                    title: this.$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.QUESTION.TITLE'),
                    subtitle: this.$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.QUESTION.SUBTITLE'),
                    items: {
                        'question.uuid': {
                            enabled: {
                                conditions: {
                                    atLeastOneOfGroup: ['question.name.number', 'question.name.label'],
                                },
                            },
                        },
                        'question.name.number': {
                            enabled: {
                                conditions: {
                                    atLeastOneOfGroup: ['question.uuid', 'question.name.label'],
                                },
                            },
                        },
                        'question.name.label': {
                            enabled: {
                                conditions: {
                                    atLeastOneOfGroup: ['question.uuid', 'question.name.number'],
                                },
                            },
                        },
                        'question.content': null,
                        'question.type': null,
                        'question.content.options': null,
                        'question.includeSkipped': null,
                    },
                },
                {
                    title: this.$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.STRUCTURE.ANSWER.OPTIONS.TITLE'),
                    items: {
                        'answer.options.uuid': {
                            radio: {
                                whenTrue: ['answer.format.kv'],
                                group: 'answer.options',
                            },
                            enabled: {
                                conditions: {
                                    atLeastOneOfGroup: ['answer.options.text', 'answer.options.value'],
                                },
                            },
                        },
                        'answer.options.text': {
                            radio: {
                                whenTrue: ['answer.format.kv'],
                                group: 'answer.options',
                            },
                            enabled: {
                                conditions: {
                                    atLeastOneOfGroup: ['answer.options.uuid', 'answer.options.value'],
                                },
                            },
                        },
                        'answer.options.value': {
                            radio: {
                                whenTrue: ['answer.format.kv'],
                                group: 'answer.options',
                            },
                            enabled: {
                                conditions: {
                                    atLeastOneOfGroup: ['answer.options.uuid', 'answer.options.text'],
                                },
                            },
                        },
                        'answer.format.kv': {
                            enabled: {
                                conditions: {
                                    onlyOneOf: ['answer.options.uuid', 'answer.options.text', 'answer.options.value'],
                                },
                            },
                            onCheck: ['answer.options.text'],
                        },
                    },
                },
            ] as OptionGroup[],
            options: { } as Record<string, boolean>,
            previewTab: 1,
            selectedOptions: [] as string[],
            conversationId: null,
            payloadSource: 1,
            legacyOutput: null as string | null,
            output: '{ }',
        };
    },
    created() {
        this.options = this.getDefaultOptions();
    },
    computed: {
        devMode(): boolean {
            return this.$route.query.dev == 'true';
        },
        version(): number {
            if (this.actionModel.params === null) {
                return 1;
            }

            return this.actionModel.params.version;
        },
        questionInstructions(): InstructionModel[] {
            return this.script.instructions?.filter((i: InstructionModel) => (i.type === 'question')) ?? [];
        },
        selectedQuestionInstructions(): InstructionModel[] {
            return this.questionInstructions
                .filter((qi) => this.selectedQuestionsUuids?.includes(qi.question?.uuid ?? 'noUuid'));
        },
        showOutputPreview(): boolean {
            return this.wizardStep > 1 && this.wizardStep < 4;
        },
        isNotLegacy(): boolean {
            return this.version > 1;
        },
        actionModel(): ActionModel {
            return (this.wizard ? this.draftAction : this.action);
        },
        payload(): string|null {
            return [this.legacyOutput, this.output][this.payloadSource];
        },
        communitySlug(): string {
            const {communitySlug} = this.$route.params;
            return communitySlug;
        },
        triggerParamsJson(): string {
            return JSON.stringify(this.actionModel?.params?.trigger);
        },
    },
    methods: {
        getQuestionLabel(i: InstructionModel): string {
            if (i.label === `q${i.order + 1}`) {
                return `q${i.order + 1}`;
            }

            return `q${i.order + 1}${i.label ? ': ' : ''}${i.label ?? ''}`;
        },
        resolveTranslation(key: string, optional = false): TranslateResult {
            const v = this.$t(key);

            if (!optional) return v;

            return v !== key ? v : '';
        },
        async updatePayloadPreview(): Promise<void> {
            this.getPayloadPreview(
                this.communitySlug,
                this.script.uuid,
                this.selectedQuestionsUuids,
                this.selectedOptions,
                2,
            ).then((payload) => {
                this.output = payload;
            });

            if (this.$route.query.dev == 'true') {
                this.getPayloadPreview(
                    this.communitySlug,
                    this.script.uuid,
                    this.selectedQuestionsUuids,
                    this.selectedOptions,
                    1,
                ).then((payload) => {
                    this.legacyOutput = payload;
                });
            }
        },
        async getPayloadPreview(
            communitySlug: string,
            uuid: string,
            questions: string[]|null,
            options: string[],
            version = 2,
        ): Promise<string> {
            const previewOutput = await ChatApi.previewPayload(
                communitySlug,
                uuid,
                questions,
                options,
                version,
                this.conversationId,
            );

            const outputString = JSON.stringify(previewOutput, null, 2);
            if (outputString == undefined) return '';

            return outputString;
        },
        getDefaultOptions(): Record<string, boolean> {
            return {
                'question.uuid': true,
                'question.name.number': true,
                'question.name.label': true,
                'question.content': true,
                'question.type': true,
                'question.content.options': true,
                'question.includeSkipped': true,
                'answer.format.kv': false,
                'answer.options.uuid': true,
                'answer.options.text': true,
                'answer.options.value': true,
            };
        },
        isScriptLive(): boolean {
            if (this.script.status === undefined) return false;
            return [STATUS_LIVE, STATUS_CLOSED].includes(this.script.status);
        },
        copyActionFrom(source: ActionModel): ActionModel {
            const newAction = new ActionModel();

            newAction.uuid = source.uuid;
            newAction.type = source.type;
            newAction.params = {...source.params};

            return newAction;
        },
        finishWizard(): void {
            this.wizard = false;
            this.$emit('undraft');
        },
        validateUuid(): boolean|null {
            return this.conversationId !== null
                ? /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(this.conversationId!) : null;
        },
        wizardStepValid(): boolean {
            switch(this.wizardStep) {
                case 1:
                    return this.action.params?.endpoint !== '' && /(http:|https:)+[^\s]+[\w]/.test(this.action.params?.endpoint);
                default:
                    return true;
            }
        },
    },
    beforeMount(): void {
        if (this.wizard) {
            this.draftAction = this.copyActionFrom(this.action);
        }

        const params = this.action.params || {
            endpoint: '',
            version: 1,
            labels: {},
        };

        if (params.wizard !== undefined) {
            this.wizard = params.wizard !== null ? params.wizard : false;
        }

        if (params.questions !== undefined) {
            this.selectedQuestionsUuids = params.questions;
        }

        // Ensure that labels is always an object.
        if (!(typeof params.labels === 'object')
            || params.labels instanceof Array
        ) {
            params.labels = Object.create({});
        }

        if (params.options === undefined) {
            this.options = this.getDefaultOptions();
            this.selectedOptions = Object.entries(this.options).filter(([, v]) => v).map(([k]) => k);
        } else {
            this.options = (params.options || []).reduce(
                (acc: Record<string, boolean>, option: string) => {
                    acc[option] = true;
                    return acc;
                },
                {} as Record<string, boolean>,
            );

            this.selectedOptions = Object.keys(this.options);
        }

        if (params.version === undefined || params.version === null) {
            params.version = 1;
        }

        this.actionModel.params = params;
    },
});
</script>

<style lang="scss">
#v-app-embed.v-application.dynamic {
    background-color: inherit !important;
    height: auto !important;
    width: auto !important;
    min-height: auto;
    max-height: auto;

    .v-application--wrap {
        min-height: auto !important;
    }
}
</style>

<style lang="scss" scoped>
@import '~vuetify/dist/vuetify.min.css';

::v-deep {
    @import 'src/styles/colors';

    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    @import '~bootstrap/scss/utilities';

    @import '~bootstrap/scss/grid';

    @import '~bootstrap/scss/forms';
    @import '~bootstrap/scss/navbar';
    @import '~bootstrap/scss/nav';
    @import '~bootstrap/scss/card';
    @import '~bootstrap/scss/badge';
    @import '~bootstrap/scss/list-group';

    //@import "~bootstrap/scss/utilities/stretched-link";
    //@import "~bootstrap/scss/helpers/color-bg";
}

.row {
    flex: 0 0 auto;
}

.config {
    flex-grow: 1;
    min-width: 30%;
    max-width: 50%;
    overflow-y: auto;
    overflow-x: hidden;

    &.full {
        max-width: 100%;
    }
}

.output-preview {
    padding: 0 1rem;
    flex-grow: 1;
    min-width: 50%;
}

.v-item-group.gapped {
    button {
        margin-right: 0.5rem;
    }
}
</style>
