var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-switch',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('FaIcon',{attrs:{"icon":"bolt"}}),_vm._v(" "+_vm._s(_vm.$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.TRIGGER.SWITCH.LABEL'))+" ")]},proxy:true}]),model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1)],1),(_vm.enabled)?_c('div',[(_vm.enabled)?_c('v-row',[_c('v-col',[_c('p',[_vm._v(_vm._s(_vm.$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.TRIGGER.TIMEOUT.DESCRIPTION')))]),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.preset),callback:function ($$v) {_vm.preset=$$v},expression:"preset"}},[_vm._l((_vm.presets),function(ref){
var amount = ref.amount;
var unit = ref.unit;
var label = ref.label;
return _c('v-btn',{key:(amount + "_" + unit),attrs:{"value":(amount + "_" + unit)},on:{"click":function($event){return _vm.setConfig(amount, unit)}}},[_vm._v(" "+_vm._s(label)+" ")])}),_c('v-btn',{attrs:{"value":"custom"},on:{"click":function($event){_vm.custom = true}}},[_vm._v(" Custom ")])],2)],1),_c('v-col')],1):_vm._e(),(_vm.enabled && _vm.custom)?_c('v-row',{staticClass:"mt-2",attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-text-field',{attrs:{"filled":"","dense":"","type":"number","label":"amount","min":"1","placeholder":"2"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"filled":"","dense":"","item-text":"text","item-value":"value","label":"unit","items":_vm.units},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+_vm._s((_vm.amount > 1 ? 's' : ''))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+_vm._s((_vm.amount > 1 ? 's' : ''))+" ")]}}],null,false,3353676892),model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }