// eslint-disable-next-line max-classes-per-file
import MessageModel from '@/models/message.model';
import QuestionModel from '@/models/question.model';
import ActionModel from '@/models/action.model';

import {v4 as uuidv4, v4 as uuid4v} from 'uuid';
import ChoiceModel from '@/models/choice.model';

export default class InstructionModel {
    uuid = uuid4v() as string;

    typing_delay = 0;

    order = 0;

    type = 'empty';

    condition = null as string | null;

    label = null as string | null;

    can_revert = true;

    draft = false;

    messages = null as MessageModel[] | null;

    question = null as QuestionModel | null;

    action = null as ActionModel | null;
}

export class InstructionModelExt {
    static GetMessageForLocale(self: InstructionModel, locale: string, fallbackLocale: string): MessageModel | null {
        if (self.messages == null) return null;
        let preferred = null;
        let fallback = null;
        for (let i = 0; i < self.messages.length; i += 1) {
            const msg = self.messages[i] as MessageModel;
            if (msg.locale === locale) {
                preferred = msg;
                break;
            } else if (msg.locale === fallbackLocale) {
                fallback = msg;
            }
        }

        return preferred || fallback;
    }

    static copy(self: InstructionModel): InstructionModel {
        const copy = JSON.parse(JSON.stringify(self));

        copy.uuid = uuidv4();
        copy.label = null;
        if (copy.initialAction) {
            copy.initialAction.uuid = uuidv4();
        }

        if (copy.action) {
            copy.action.uuid = uuidv4();
        }

        if (copy.messages) {
            copy.messages.map((msg: MessageModel) => {
                // eslint-disable-next-line no-param-reassign
                msg.uuid = uuidv4();
                return msg;
            });
        }

        if (copy.question) {
            copy.question.uuid = uuidv4();
            if (['multiple', 'choice'].includes(copy.question.type)) {
                copy.question.choices.map((choice: ChoiceModel) => {
                    // eslint-disable-next-line no-param-reassign
                    choice.uuid = uuidv4();
                    return choice;
                });
            }
        }

        if (self.type === 'action' && self.action?.type === 'send2api' && self.action?.params?.trigger?.enabled === true) {
            copy.action.params.trigger.enabled = false;
        }

        return copy;
    }
}
