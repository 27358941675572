<script lang="ts">
import Vue from 'vue';
import ActionModel from '@/models/action.model';
import TriggerConfigurator from '@/components/chat/configurators/action/Send2API/TriggerConfigurator.vue';
import LabelConfigurator from '@/components/chat/configurators/action/Send2API/LabelConfigurator.vue';

export default Vue.extend({
    name: 'EndpointConfigurator',
    components: {LabelConfigurator, TriggerConfigurator},
    props: {
        value: [Object, ActionModel],
        readonly: Boolean,
    },
    data() {
        return {
            versionOptions: [
                { text: 'v1: Static (Structure can\'t be changed)', value: 1 },
                { text: 'v2: Dynamic', value: 2 },
            ]
        }
    }
});
</script>

<template>
    <div>
        <v-form>
            <v-text-field
                filled dense
                type="text"
                :readonly="readonly"
                id="endpoint"
                name="endpoint"
                placeholder="https://example.com/api/v1/confetti"
                v-model="value.params.endpoint">
                <template #label>
                    <span class="ml-1">{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.FIELD.ENDPOINT.LABEL') }}</span>
                </template>
                <template #prepend>
                    <FaIcon icon="server" />
                </template>
            </v-text-field>
            <v-select filled :items="versionOptions" v-model="value.params.version" :readonly="readonly">
                <template #label>

                    <span class="ml-1">Version</span>
                </template>
                <template #prepend>
                    <FaIcon icon="code-fork" />
                </template>
            </v-select>
            <h3>
                <FaIcon icon="tags" />
                <span class="ml-1">{{ $t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.FIELD.LABELS.LABEL') }}</span>
            </h3>
            <v-row>
                <v-col cols="4">
                    <LabelConfigurator
                        :subject="value.params.labels"
                        :readonly="readonly"
                        :placeholder="$t('COMMUNITY.CHAT.INSTRUCTION.SEND2API.FIELD.LABELS.PLACEHOLDER')"
                    />
                </v-col>
            </v-row>

            <TriggerConfigurator v-model="value.params.trigger" />
        </v-form>
    </div>
</template>

<style scoped lang="scss">
    @import 'src/styles/colors';

    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    @import '~bootstrap/scss/input-group';

    @import '~bootstrap/scss/utilities';

    .input-group-text {
        width: 100%;
    }
</style>
